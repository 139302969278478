<template>
  <b-modal
    ref="round-modal"
    hide-footer
    title="เลือกงวดหวย"
    size="xl"
    class="round-modal"
  >
    <div class="row no-gutters">
      <div
        v-for="item in items"
        class="col-md-4 p-2"
        :key="item._id"
      >
        <div class="card mb-0 pointer" @click="selectRound(item)" :class="current._id===item._id ? 'alert-primary border-primary' : 'alert-secondary'">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between">
              <div>{{item.note.marketTitle}}</div>
              <div>{{item.roundDate.date | roundDate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from '@/helpers/moment'

export default {
  name: 'RoundModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    current: {
      type: Object,
      default: ()=>{
        return { _id: null }
      }
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    showModal() {
      this.$refs['round-modal'].show()
    },
    hideModal() {
      this.$refs['round-modal'].hide()
    },
    selectRound(item) {
      this.$emit('select', item)
      this.hideModal()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  },
  filters: {
    roundDate(date) {
      if(!date)
        return '--/--/----'

      const roundDate = moment(date).format('D')
      const roundMonth = moment(date).format('MMMM')
      const roundYear = moment(date).add(543, 'y').format('YYYY')

      return `${roundDate} ${roundMonth} ${roundYear}`
    }
  }
}
</script>
<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
