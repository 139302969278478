<template>
  <div class="pt-2">
    <div>
      <span>{{roundText}}</span>
      <button @click="toggleRound" class="btn btn-link p-0 ml-2">(เลือกงวด)</button>
    </div>

    <div class="card">
      <AllTickets :items="items" :round="currentRound" @reload="loadTickets" />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>

    <RoundModal :isShow="isShowRoundModal" :current="currentRound" :items="roundItems" @select="loadTickets" @close="closeModal" />
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import AllTickets from './AllTickets'
import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'
import RoundModal from './components/RoundModal'

export default {
  name: 'RoundTickets',
  components: {
    AllTickets,
    RoundModal
  },
  data() {
    return {
      data: null,
      rounds: null,
      currentRound: null,
      isLoading: false,
      isShowRoundModal: false
    }
  },
  computed: {
    roundItems() {
      return (this.rounds || [])
    },
    items() {
      return (this.data || []).filter((item)=>{
        return ['Accepted', 'Canceled', 'Refund', 'Processing', 'Completed'].includes(item.status) && item?.summary?.credit
      })
    },
    roundText() {
      if(!this.currentRound)
        return '-'

      const roundDate = moment(this.currentRound.roundDate.date).format('D')
      const roundMonth = moment(this.currentRound.roundDate.date).format('MMMM')
      const roundYear = moment(this.currentRound.roundDate.date).add(543, 'y').format('YYYY')

      return `${this.currentRound.note.marketTitle} ::: งวดวันที่ ${roundDate} ${roundMonth} ${roundYear}`
    }
  },
  methods: {
    loadRounds() {
      this.isLoading = true
      ReportService.getRounds().then((response)=>{
        if(response.success) {
          this.rounds = response.data
          this.loadTickets(this.rounds?.[0])
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    loadTickets(round) {
      if(!round)
        return

      this.currentRound = round
      this.isLoading = true
      ReportService.getTicketByRoundId(this.currentRound._id).then((response) => {
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายการโพยไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleRound() {
      this.isShowRoundModal = true
    },
    closeModal() {
      this.isShowRoundModal = false
    }
  },
  mounted() {
    this.loadRounds()
  }
}
</script>
